import axios from "axios";
import { useSearchParams } from "react-router-dom";
import surveyJson from "./surveyJsPartie1.json";
import surveyTheme from "./survey_theme";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";
import { autocomplete } from "surveyjs-widgets";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import Prompt from './prompt';
import FormSurvey from './FormSurvey';

const { v4: uuidv4 } = require("uuid");

// autocomplete pour la liste des médicaments
window["$"] = window["jQuery"] = $;
require("easy-autocomplete/dist/jquery.easy-autocomplete.js");
autocomplete(SurveyCore);
const SURVEY_ID = uuidv4();
const HOST = process.env.REACT_APP_HOST;

function submitSurveyData(data) {
  
  const ENDPOINT_URL = "https://"+HOST+"/runSurveyAddPostres";
   
  fetch(ENDPOINT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('data=',data);
      if(process.env.REACT_APP_SERVER == "DEMO")
      window.top.location.href = "https://"+HOST+"/"+process.env.REACT_APP_REP+"?survey=1&lang="+data.data.lang+"&client_reference_id="+SURVEY_ID;     
    else
      window.top.location.href = process.env.REACT_APP_URL_STRIPE + data.data.email + "&client_reference_id="+SURVEY_ID;     
    })
    .catch((error) => console.error(error));
}

function App() {
  const [searchParams] = useSearchParams();
  const survey = new Model(surveyJson);
  console.log('process.env.REACT_APP_SERVER =',process.env.REACT_APP_SERVER );
  console.log('HOST =',HOST);
  
  // Appliquer le thème CSS importé
  survey.applyTheme(surveyTheme);
  survey.locale = searchParams.get("lang");

  var converter = new Converter();
  survey.onTextMarkdown.add(function (survey, options) {
    var str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  });

  survey.onUploadFiles.add((_, options) => {
    let data = new FormData();

    options.files.forEach((file) => {
      //data.append('file', file);
      const newFileName = `${SURVEY_ID}.pdf`;
      const newFile = new File([file], newFileName, { type: file.type });

      data.append("file", newFile);
    });

    axios.post("https://"+HOST+"/uploadSingleFile", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        options.callback(
          options.files.map((file) => {
            const newFileName = `${SURVEY_ID}.pdf`;
            const newFile = new File([file], newFileName, { type: file.type });
            console.log(data);
            if (data.data =="hasPassword")
            {
              alert("Le fichier chargé a besoin d'un mot de passe. Merci de le saisir dans l’encadré dédié avant de cliquer sur 'Suivant'.");
            }

            return {
              file: newFile,
              content: data[newFileName],
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        options.callback([], ["An error occurred during file upload."]);
      });
  });

  survey.onComplete.add((survey) => {
    let prompt = searchParams.get("prompt");
    survey.setValue("prompt", prompt);

    let lang = searchParams.get("lang");
    survey.setValue("lang", lang);

    let header = searchParams.get("header");
    survey.setValue("header", header);

    let footer = searchParams.get("footer");
    survey.setValue("footer", footer);

    survey.setValue("survey_id", SURVEY_ID);
    survey.setValue("servertype", process.env.REACT_APP_SERVER);
    console.log(JSON.stringify(survey.data, null, 3));
    submitSurveyData(survey.data);
  });

  survey.onValueChanged.add(function (sender, options) {
    console.log(options.name);
    if (options.name === "question4") {
      var acceptTerms = sender.getValue("question4");
      // alert(acceptTerms);
       if (acceptTerms === true) {
        $(".sd-navigation__complete-btn").css("visibility", "visible");
       } else {
        $(".sd-navigation__complete-btn").css("visibility", "hidden");
       }
    }
  }); 
  

  if (searchParams.get("activeprompt") == "1") return <Prompt />;
  if (searchParams.get("survey") == "1") return <FormSurvey />;

  return (
    <>
      <style>
        {`
          .sd-navigation__complete-btn {
            visibility: hidden;
          }
          .sd-progress-buttons__container{
            align-self: center;
            min-width: min(100%, 650px);
          }
          .sd-body.sd-body--static{
            max-width: calc((var(--sjs-base-unit, var(--base-unit, 8px)))*130);
            padding-top: 0px;
          }
          .sd-progress-buttons__page-title{
            visibility: hidden;
          }

          .sv-components-container-center {
            visibility: hidden;
            margin-bottom: -10px;
          }
        `}
      </style>
      <Survey model={survey} />
    </>
  );
}

export default App;
