import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import surveyJson from "./surveyJsPartie2.json";
import surveyTheme from "./survey_theme";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";
import { autocomplete } from "surveyjs-widgets";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
const stripe = require('stripe')(process.env.REACT_APP_KEY_STRIPE);

const HOST = process.env.REACT_APP_HOST;
window.$ = window.jQuery = $;
autocomplete(SurveyCore);

const fetchSurveyData = async (url, setter) => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    setter(data[0]); // Assumes data[0] contains the needed information
  } catch (error) {
    console.error("Error fetching survey data:", error);
  }
};

function submitSurveyData(data) {
  const ENDPOINT_URL = `https://${HOST}/runSurveyPaiment`;
  const UPDATE_URL = `https://${HOST}/runSurveyAddPostres/UPDATE`;

  fetch(UPDATE_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

  fetch(ENDPOINT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error("Error submitting survey data:", error));
}

function App() {
  const [searchParams] = useSearchParams();
  const [surveyData, setSurveyData] = useState({});
  const [clientReferenceId, setClientReferenceId] = useState(null);
  const survey = new Model(surveyJson);

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    let client_reference_id = searchParams.get("client_reference_id");

    if (!client_reference_id && sessionId) {
      stripe.checkout.sessions.retrieve(sessionId).then((data) => {
        const id = `${data.client_reference_id}`;
        console.log("id=",id);
        setClientReferenceId(id);
        fetchSurveyData(`https://${HOST}/getSurvey?Id=${id}`, setSurveyData);
      }).catch((error) => console.error("Error retrieving session:", error));
    } else {
      setClientReferenceId(client_reference_id);
      fetchSurveyData(`https://${HOST}/getSurvey?Id=${client_reference_id}`, setSurveyData);
    }
  }, [searchParams]);

  useEffect(() => {
    if (Object.keys(surveyData).length > 0) {
      const { prompt = "", lang = "fr", header = "", footer = "", filepassword = "", email = "", lastname = "", firstname = "" } = surveyData;
      survey.locale = lang;
      survey.applyTheme(surveyTheme);
      console.log({ prompt, lang, header, footer, filepassword, email });

      survey.onTextMarkdown.add((_, options) => {
        const converter = new Converter();
        const htmlText = converter.makeHtml(options.text);
        options.html = htmlText.substring(3, htmlText.length - 4);
      });

      survey.onComplete.add((survey) => {
        const surveyResults = {
          ...survey.data,
          email,
          passwordFile: filepassword,
          prompt,
          lang,
          header,
          footer,
          nameandfirstname: `${lastname} ${firstname}`,
          survey_id: clientReferenceId?.replace(".pdf", ""),
          session_id: searchParams.get("session_id"),
        };
        console.log(JSON.stringify(surveyResults, null, 3));
        submitSurveyData(surveyResults);
      });
    }
  }, [surveyData, survey, clientReferenceId, searchParams]);

  return <Survey model={survey} />;
}

export default App;
